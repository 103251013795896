import React from 'react';
import { useTrail, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { withTranslation } from 'react-i18next';

import PostOverview from '../components/component.post-overview';

const LatestSpotlight = ({ title, subtitle, link, t, language }) => {
    const postData = useStaticQuery(graphql`

        fragment latestPostsContent on WORDPRESS_Post {
            title
            slug
            uri
            date
            featuredImage {
                node {
                    altText
                    sourceUrl
                    imageFile {
                        childImageSharp {
                            thumbnail: fixed(width: 347, height: 215) {
                                ...GatsbyImageSharpFixed_withWebp_tracedSVG
                            }
                        }
                    }
                }
            }
            categories(first: 1) {
                nodes {
                    name
                    slug
                }
            }
        }

        query latestSpotlightPosts {
            wordpress {
                EN: posts(first: 3, where: {orderby: {field: DATE, order: DESC}, status: PUBLISH, language: EN, categoryName: "Club Spotlights"}) {
                    nodes {
                        ...latestPostsContent
                    }
                }
                FR: posts(first: 3, where: {orderby: {field: DATE, order: DESC}, status: PUBLISH, language: FR, categoryName: "Club Spotlights"}) {
                    nodes {
                        ...latestPostsContent
                    }
                }
                ES: posts(first: 3, where: {orderby: {field: DATE, order: DESC}, status: PUBLISH, language: ES, categoryName: "Club Spotlights"}) {
                    nodes {
                        ...latestPostsContent
                    }
                }
                ZH: posts(first: 3, where: {orderby: {field: DATE, order: DESC}, status: PUBLISH, language: ZH, categoryName: "Club Spotlights"}) {
                    nodes {
                        ...latestPostsContent
                    }
                }
                DE: posts(first: 3, where: {orderby: {field: DATE, order: DESC}, status: PUBLISH, language: DE, categoryName: "Club Spotlights"}) {
                    nodes {
                        ...latestPostsContent
                    }
                }
            }
        }
    `);


    let languageCode = "EN";
    // could be either language.code or just language string:
    if (typeof language !== "undefined") {
        if (typeof language.code !== "undefined") {
            languageCode = language.code;
        } else {
            languageCode = language;
        }
    }

    let linkDefault = "/category/club-spotlights/";
    if (languageCode != "EN") {
        linkDefault = "/" + languageCode.toLowerCase() + "/category/club-spotlights/";
    }

    let posts;

    // language is taken from the page's language code
    if (typeof postData.wordpress[languageCode] !== "undefined") {
        posts = postData.wordpress[languageCode].nodes;
    } else {
        posts = postData.wordpress['EN'].nodes;
    }

    const [ref, inView] = useInView({
        threshold: .3,
        triggerOnce: true,
    });

    const trail = useTrail(posts.length, {
        config: { tension: 280, friction: 60 },
        opacity: inView ? 1 : 0,
        transform: inView ? 'scale(1)' : 'scale(0)',
        from: { opacity: 0, transform: 'scale(0)' },
    });

    return (
        <div className="container">
            <div className="xs-pt-50 xs-pb-50">
                <div className="c-section-title">
                    <h2 className="c-section-title__title">{title ? title : t("latest.title-spotlight")}</h2>
                    <p className="c-section-title__desc">{subtitle ? subtitle : t("latest.sub-title-spotlight")} <Link className="c-section-title__link" to={link ? link.url : linkDefault}>{t("latest.link-label-spotlight")} <i className="c-section-title__arrow fal fa-long-arrow-right"></i></Link></p>
                </div>

                <div ref={ref} className="row">
                    {trail.map(({ ...rest }, index) => (
                        <animated.div key={index} style={rest} className={index < 1 ? "col-md-4" : "col-sm-4 d-none d-md-block"}>
                            <PostOverview post={posts[index]} language={language} />
                        </animated.div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(LatestSpotlight);

